import React, { Component } from 'react';

export default class Location extends Component {
  render() {
    return(
      <div className="location">
        <p><b>Address: Meeee Pilates Studio</b><br/>No.103, Building No.22, Central Park, Chaoyang District, Beijing<br/>&#21271;&#20140;&#24066;&#26397;&#38451;&#21306;&#26032;&#22478;&#22269;&#38469;22&#21495;&#27004;103&#21495;Meeee</p>
        <p>Nearby Subway stops: Dongdaqiao/&#19996;&#22823;&#26725; (Line 6) or Jintaixizhao/&#37329;&#21488;&#22805;&#29031; (Line 10)</p>
        <img src="location.jpg" alt="Location" />
      </div>
    )
  }
}