import React from 'react';
import Info from './Info';
import BaseSlideshow from './BaseSlideshow';

export default class Home extends BaseSlideshow {
  constructor(props) {
    super(props);

    // Override numSlides.
    this.state = {
      ...this.state,
      numSlides: 2
    };
  }

  render() {
    return(
      <div className="container">
        <div className="slideshow-container">
          {this.renderSlide("front.jpg", "Studio front", 0)}
          {this.renderSlide("meeee.jpg", "Meeee", 1)}
        </div>

        <div>
          {this.renderDot(0)}
          {this.renderDot(1)}
        </div>

        <Info /> 
      </div>
    )
  }
}