import React, { Component } from 'react';

export default class Menu extends Component {
  render() {
    return(
      <div className="menu">
        <img src="menu.jpg" alt="Menu" />
      </div>
    )
  }
}