import React from 'react';
import BaseSlideshow from './BaseSlideshow';

export default class Team extends BaseSlideshow {
  constructor(props) {
    super(props);

    // Override numSlides and add additional state variables.
    this.state = {
      ...this.state,
      numSlides: 5,
      selectedBadge: null,
      hoveredBadge: null,
      selectedSlides: this.slidesData,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedBadge !== this.state.selectedBadge) {
      const selectedSlides = this.slidesData.filter(
        slide => !this.state.selectedBadge || slide.staff.includes(this.state.selectedBadge)
      );
      this.setState({ slideIndex: 0, numSlides: selectedSlides.length, selectedSlides });
    }
  }

  handleMouseEnter = (name) => {
    this.setState({ hoveredBadge: name });
  }

  handleMouseLeave = () => {
    this.setState({ hoveredBadge: null });
  }

  handleClick = (name) => {
    this.setState(prevState => ({
        selectedBadge: prevState.selectedBadge === name ? null : name,
    }));
  }

  getBadgeClass = (name) => {
    const { selectedBadge, hoveredBadge } = this.state;
    let badgeClass = "badge rounded-pill ";

    if (selectedBadge === name) {
      badgeClass += "text-bg-dark";
    } else if (hoveredBadge === name) {
      badgeClass += "text-bg-warning";
    } else {
      badgeClass += "text-bg-light";
    }
    return badgeClass;
  }

  slidesData = [
    { src: "team1.jpg", staff: ["Nina"] },
    { src: "team3.jpg", staff: ["Aniki"] },
    { src: "team2.jpg", staff: ["Nina", "Aniki"] },
    { src: "team4.jpg", staff: ["Nina"] },
    { src: "team5.jpg", staff: ["Aniki", "Nina"] },
  ];

  introData = {
    "Nina": "<p>Hi, I am Nina.</p><p>As a former actuary, I often think about the possibility of integrating meaningful things with my personal preference, especially when I was facing th computer screen full of codes and numbers. I quit a steady actuarial job when I came back from the US at the age of 30 and entered the fitness area which was unknown to me but full of excitement.</p><p>I had been a personal coach and group coach among different gyms, and a physical trainer for national team athletes and celebrities. Eventually, I found and fell in love with Pilates which is the driving power to obtain inner joy. Pilates not only improves my living style and attitude towards life, but also plays a key role to bridge myself to the world in a real and positive way.</p><p><strong>This is the birth story of Meeee.</strong></p>",
    "Aniki": "<p>Hi, this is Aniki and I used to work in the engineering industry and the working pressure and physical problems prompted me to seek a solution, and I was convinced that Pilates was the right answer after several attempts.</p><p>Traditional fitness culture was full of high-intensive, fierce, or even desperate practice which scared me off, but Pilates opened a dialogue between my brain and body, and you can feel precise and control throughout the entire process of practice. I was aware that Pilates training is suitable for almost everyone since we need not only a high-intensive training but also a practice to adjust and control your joints and muscle group smoothly and gently.</p><p>Not surprisingly, a straight posture and more flexible spine, my body was experiencing an amazing change after I practiced Pilates. I believe Pilates was a perfect way to explore yourself inward.</p><p>Try to make every client feel their body changes and relieve their physical sufferings, and share my knowledge and benefits with them is my original purpose of becoming a Pilates coach.</p><p>We learn by teaching. I love and feel proud of what I am doing, because Pilates consistently establishes a connection with my body and explore myself in a scientific way. I hope we can share and enjoy the experience together.</p>",
    "YY": "YY is a dedicated and enthusiastic member of our team with over 5 years of experience in the industry.",
  };

  render() {
    const { selectedSlides, slideIndex, selectedBadge } = this.state;

    return(
      <div className="container">
        <div className="slideshow-container">
          {selectedSlides.map((slide, idx) => this.renderSlide(slide.src, slide.staff.join(", "), idx, idx === slideIndex))}
        </div>

        <div>
          {Array.from({ length: selectedSlides.length }, (_, idx) => this.renderDot(idx))}
        </div>

        <div className="team">
          <div className={this.getBadgeClass('Nina')}
            onMouseEnter={() => this.handleMouseEnter('Nina')}
            onMouseLeave={this.handleMouseLeave}
            onClick={() => this.handleClick('Nina')}>
            <img src="nina.png" alt="Nina" /> Nina
          </div>
          <div className={this.getBadgeClass('Aniki')}
            onMouseEnter={() => this.handleMouseEnter('Aniki')}
            onMouseLeave={this.handleMouseLeave}
            onClick={() => this.handleClick('Aniki')}>
            <img src="aniki.png" alt="Aniki" /> Aniki
          </div>

          {selectedBadge && 
            <div className="team-intro" dangerouslySetInnerHTML={{ __html: this.introData[selectedBadge] }}>
            </div>
          }
        </div>
      </div>
    )
  }
}