import React from 'react';
import {Link} from "react-router-dom";

const Info = () => (
  <div className="business-info">
    <div>
      <h1>Meeee Pilates Studio</h1>
      <p>with Gyrotonic&reg; Method</p>
      <p><Link to="/location">Address</Link>: No.103, Building No.22, Central Park<br/>Chaoyang District, Beijing</p>
    </div>
    <img src="qrcode.jpg" alt="QR Code" />
  </div>
);

export default Info;