import Home from './Home';
import Location from './Location';
import Menu from './Menu';
import Studio from './Studio';
import Team from './Team';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";

function App() {
  return (
    <Router>
    <nav className="navbar navbar-expand navbar-dark" style={{'background-color': '#c27f6f'}}>
      <div className="navbar-nav">
        <div className="navbar-brand mb-0"><img src="logo.png" alt="Logo" />&nbsp;&nbsp;&nbsp;</div>
        <NavLink
          to="/"
          className={({ isActive }) => isActive ? 'nav-item nav-link active' : 'nav-item nav-link'}>
          Home
        </NavLink>
        <NavLink
          to="/menu"
          className={({ isActive }) => isActive ? 'nav-item nav-link active' : 'nav-item nav-link'}>
          Menu
        </NavLink>
        <NavLink
          to="/team"
          className={({ isActive }) => isActive ? 'nav-item nav-link active' : 'nav-item nav-link'}>
          Team
        </NavLink>
        <NavLink
          to="/studio"
          className={({ isActive }) => isActive ? 'nav-item nav-link active' : 'nav-item nav-link'}>
          Studio
        </NavLink>
        <NavLink
          to="/location"
          className={({ isActive }) => isActive ? 'nav-item nav-link active' : 'nav-item nav-link'}>
          Location
        </NavLink>
      </div>
    </nav>
    <div className="container pt-3">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/team" element={<Team />} />
        <Route path="/studio" element={<Studio />} />
        <Route path="/location" element={<Location />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
