import React from 'react';
import BaseSlideshow from './BaseSlideshow';

export default class Studio extends BaseSlideshow {
  constructor(props) {
    super(props);

    // Override numSlides.
    this.state = {
      ...this.state,
      numSlides: 5
    };
  }

  render() {
    return(
      <div className="container">
        <div className="slideshow-large-container">
          {this.renderSlide("studio1.jpg", "Reformer", 0)}
          {this.renderSlide("studio2.jpg", "Entrance", 1)}
          {this.renderSlide("studio3.jpg", "Reception", 2)}
          {this.renderSlide("studio4.jpg", "Exercise", 3)}
          {this.renderSlide("studio5.jpg", "Photos", 4)}
        </div>

        <div>
          {this.renderDot(0)}
          {this.renderDot(1)}
          {this.renderDot(2)}
          {this.renderDot(3)}
          {this.renderDot(4)}
        </div>
      </div>
    )
  }
}