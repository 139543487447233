import React, { Component } from 'react';

export default class BaseSlideshow extends Component {
  state = {
    slideIndex: 0,
    numSlides: 1  // default
  };

  renderSlide = (src, alt, idx) => {
    const { slideIndex } = this.state;

    return (
      <div className={`slide ${slideIndex === idx ? 'active' : ''}`}>
        <img src={src} alt={alt} />
      </div>
    );
  }

  renderDot = (idx) => {
    const { slideIndex } = this.state;

    return (
      <span className={`dot ${slideIndex === idx ? 'active' : ''}`} onClick={() => this.handleDotClick(idx)}> 
      </span>
    );
  }

  handleDotClick = (idx) => {
    clearInterval(this.slideInterval);
    this.setState({ slideIndex: idx });
    // Start new interval
    this.slideInterval = setInterval(() => {
      this.setState(prevState => ({
        slideIndex: (prevState.slideIndex + 1) % this.state.numSlides,
        numSlides: this.state.numSlides
      }));
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.slideInterval);
  }

  componentDidMount() {
    // Pick one of the slides randomly to start at.
    const randomSlideIndex = Math.floor(Math.random() * this.state.numSlides);
    this.setState({ slideIndex: randomSlideIndex });

    this.slideInterval = setInterval(() => {
      this.setState(prevState => ({
        slideIndex: (prevState.slideIndex + 1) % this.state.numSlides,
        numSlides: this.state.numSlides
      }));
    }, 3000);
  }

  render() {
    return null;
  }
}